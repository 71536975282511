import React from 'react';

export function generateYears() {
  const nowDate = new Date();
  const arr = [];
  for (let i = 1940; i < nowDate.getUTCFullYear() - 3; i++) {
    arr.push(i);
  }
  return arr;
}

let formatterMonth = new Intl.DateTimeFormat("en-GB", { month: "2-digit"});
let formatterDay = new Intl.DateTimeFormat("en-GB", { day: "2-digit"});
export function calendarPreferenceFix(calendar) {
  return calendar.map((c) => {
    c.local = {};
    c.local.from = new Date(c.date + 'T' + c.fromTime+'Z' );
    c.local.to = new Date(c.date + 'T' + c.toTime+'Z' );

    c.local.date = c.date;
    c.local.fromTime = c.fromTime;
    c.local.toTime = c.toTime;
    return c;
  });
}


/**
 * type - parameter gets the set type value possible values: 
 * N - normal set
 * TBO - tie-break only set
 * P10 - Pro set 10 games
 * P8 - Pro set 8 games
 * 
 * return the possible opponents score as an array.
 * @param {string} type 
 * @param {number} score 
 */
export function opponentScore(type,  score ){
  switch (type) {
      case 'NORMAL':
          if(score<5){
              return [6];
          }else if(score===5){
              return [7];
          }else if(score===6){
              return [1,2,3,4,7];
          }else if(score===7){
              return [5,6];
          }else if(score>7 && score<10){
            return [score+2];
          }else if(score === 10){
            return [1,2,3,4,5,6,7,8, 12];
          }else{
            return [score-2, score+2];
          }
          break;
      case 'TB':
          if(score<6){
            return [7,10];
          }else if (score===6){
            return [10];
          }else if(score===7){
              return [1,2,3,4,5, 10];
          }else if(score>7 && score<10){
            return [score+2];
          }else if(score === 10){
            return [1,2,3,4,5,6,7,8,12];
          }else{
            return [score-2, score+2];
          }
          
          break;
      case 'P10':
          if(score<10){
              return [10];
          }else{
              return [1,2,3,4,5,6,7,8,9];
          }
      case 'P8':
          if(score<8){
              return [8];
          }else{
              return [1,2,3,4,5,6,7];
          }
      default:
          break;
  }
  return null;
}

export function getTimeByMinutes(minute){
  let h = parseInt(minute / 60);
  let m = minute % 60;
  h = (h+"").length===1?"0"+h:h;
  m = (m+"").length===1?"0"+m:m;
  return h+":"+m;
}
export function getMinutesByTime(time){
  let parts = time.split(":");
  return parseInt(parts[0]) * 60 + parseInt(parts[1]);
};
export function utcIsoDateTimeString(date, hour, minute){
  let m = date.getUTCMonth()+1;
  let d = date.getUTCDate();
  hour = hour || '00';
  minute = minute || '00';

  hour = (""+hour).length === 1? "0"+hour:hour;
  minute = (""+minute).length === 1? "0"+minute:minute;

  m = (""+m).length === 1? "0"+m:m;
  d = (""+d).length === 1? "0"+d:d;
  return date.getUTCFullYear()+"-"+m+"-"+d+"T"+hour+":"+minute+"Z";
}
export function utcDateString(date){
  let m = date.getUTCMonth()+1;
  let d = date.getUTCDate();
  m = (""+m).length === 1? "0"+m:m;
  d = (""+d).length === 1? "0"+d:d;
  return date.getUTCFullYear()+"-"+m+"-"+d;
}
export function utcTimeString(date){
  let hour = date.getUTCHours();
  let minute = date.getUTCMinutes();
  hour = (""+hour).length === 1? "0"+hour:hour;
  minute = (""+minute).length === 1? "0"+minute:minute;
  return hour+":"+minute;
}
export function timeToHStyle(time){
  let timeArray = time.split(":");
  return parseInt(timeArray[0])+"h"+timeArray[1];
}
export function formatUserName(user){
  if (user) {
    let name = user.firstName;
    if (user.lastName) {
      name += ' '+user.lastName;
    }
    return name;
  }
  return '';
}
export function parseQueryString(query){
  let data;
  if(query){
    if(query.startsWith("?")){
      query = query.substring(1);
    }
    data = query.split("&");
    data = data.map( i => {
      let v = i.split("=");
      return {key: v[0], value: v[1]};
    });
  }
  return data;
}
export function parseDate(date){
  var d = new Date('2020-01-10');
  d.setYear(parseInt(date.substring(0,4)));
  d.setMonth(parseInt(date.substring(5,7))-1);
  d.setDate(parseInt(date.substring(8,10)));
  return d;
}

export function getTournamentName(detail, intl) {
  let name = detail?.name;
  if (isCompass(detail?.tournamentType) && name && name.indexOf(">") > -1) {
    const compassName = name.substring(0, name.indexOf(">"));
    const directionName = "ENUM." + name.substring(name.indexOf(">") + 1, name.length);
    name = compassName + " > " + intl.formatMessage({ id: directionName });
  }
  return name ?? "";
}

export function isCompass(type) {
  return (
    type &&
    (type === "COMPASS_ELIMINATION" || type === "BACKDRAW_ELIMINATION")
  );
}