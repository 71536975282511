import React,{Component } from 'react'
import PropTypes from 'prop-types';
import profilePic from '../images/icon-no-pic.png'

class ProfilePic extends Component {

  render() {
    let url = this.props.url
    if(url && url.indexOf('://')===-1){
      url=this.props.baseURL+'thumb/'+url;
    }
    let classN = `pic ${!!this.props.selected?'selected':''}`;
    return this.props.onClick? (<div style={{backgroundImage:`url(${url || this.props.defaultProfilePic || profilePic})`}} className={classN} onClick={(e) => { 
      e.preventDefault(); this.props.onClick() 
    }} ></div>)
        : (<div style={{backgroundImage:`url(${url || this.props.defaultProfilePic || profilePic})`}} className={classN}></div>);
  }
  
}

ProfilePic.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  defaultProfilePic: PropTypes.object
};


export default (ProfilePic);
