export function getLocale() {
  let locale = navigator.language;
  if(locale && locale.length>2){
    locale = locale.substr(0,2);
  }
  const locales = ["en", "fr", "es", "de", "nl", "pt", "hu", "it"];
  if (!locale || locales.indexOf(locale)===-1) {
    locale = "en";
  }
  return locale;
}

export function getMessages(locale) {
  return window.ipm2Lang[locale];
}
