import axios from 'axios';
import qs from 'qs';

const CLIENT_ID = 'score';
const CLIENT_SECRET = 'dIanIe9SWZwVEe8vOSMTPWZcmDE8Wado';

export function getConfig() {
  return axios({
    url: '/api/config',
    method: 'GET',
    auth: {
      username: CLIENT_ID,
      password: CLIENT_SECRET
    }
  });
}
export function getLanguage(url) {
  let auth = axios.defaults.headers.common["Authorization"];
  delete axios.defaults.headers.common["Authorization"];
  let prom = axios({
    url,
    method: 'GET'
  }); 
  axios.defaults.headers.common["Authorization"]=auth;
  return prom;
}

export function getGameData(token){
    return axios({
        url: '/api/game/data/'+token,
        method: 'GET',
        auth: {
          username: CLIENT_ID,
          password: CLIENT_SECRET
        }
      });
}