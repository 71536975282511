import React from 'react';
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
import "../styles/main.scss";
import iconStores from "../images/banner-download/icon-stores.svg";
import balls from "../images/banner-download/balls.svg";

export default function BannerSlide({sport, sportColor, imgSlide}) {
    return (
        <div className='row no-gutters d-flex align-items-stretch'>
            <div className="col-lg-5 col-xl-6">
                <div className='imgDownload'>
                    <div className='cover'></div>
                    <img src={imgSlide} />
                </div>
            </div>
            <div className="col-lg-7 col-xl-6 d-flex align-items-center">
                <div className='maximize-info text-center text-md-left'>
                    <h3 className='title'>
                        <div className='d-flex flex-column flex-md-row'>
                            <div className='mr-md-2 mb-2 mb-md-1'>Maximize</div>
                            <div className='mb-2'>your</div>
                        </div>
                        <div className='mb-2 mb-md-1' style={{color: sportColor}}>{sport}</div>
                        <div className='d-flex flex-column flex-md-row align-items-center'>
                            <div className='mr-md-2 mb-2 mb-md-1'>life</div>
                            <img className="balls-title" src={balls} />
                        </div>
                    </h3>  
                    <div className='text-box'>
                        Set up your own invitations to anyone. Update your profile to tell iPlayMe2 what times, locations, gender of opponents, age range, and types of play singles, doubles, or both.
                    </div>
                    <div className='d-inline-block'>
                        <a
                            className='button-download'
                            href="http://onelink.to/iplayme2tennis"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className='d-flex align-items-center'>
                                <span>Download iPlayMe2!</span>
                                <img
                                    src={iconStores}
                                    className="img-fluid"
                                    alt="Download iPlayMe2!"
                                />
                            </div>
                        </a>
                        <div className='free-forever text-center'><FormattedMessage id="WA.ItsFreeForever"/></div>
                    </div>
                </div>
            </div>
        </div>
    );
  }