import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { getConfig, getLanguage } from "./resource";
import {getLocale} from "./internalization";
import axios from "axios";


var apiUrl = window.document.location.href; //.replace("matchscore.", "");
var idToken = apiUrl.substring(apiUrl.lastIndexOf("?t=")+3,apiUrl.length);
apiUrl = apiUrl.substring(0, apiUrl.lastIndexOf("?t="));

if(window.document.location.href.startsWith("https://matchscore.iplayme2.com")){
  axios.defaults.baseURL = "https://web.iplayme2.com";
}else if(window.document.location.href.startsWith("https://uat.matchscore.iplayme2.com")){
  axios.defaults.baseURL = "https://uat.iplayme2.com";
}else if(apiUrl.indexOf("localhost")==-1){
  axios.defaults.baseURL = "https://dev.iplayme2.com";  
}
if(idToken.indexOf('.')!=-1){
    window.location.href = "https://web.iplayme2.com";
}




getConfig()
  .then(response => {
    getLanguage(response.data.webTranslationURL)
      .then(langData => {
        window.ipm2Lang = langData.data;
        ReactDOM.render(<App language={getLocale()} idToken={idToken} config={response.data}/>, document.getElementById('root'));
      })
      .catch(error => {
        alert("No network connection. Please check you network links!");
      });
  })
  .catch(error => {
    alert("Network connection problem. Unable to reach the server. Please check you network links!");
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
