import React from 'react';
import "../styles/main.scss";
import poweredByImg from "../images/poweredBy-white.svg";

export default function PoweredBy() {
  return (
    <div className="d-flex justify-content-center my-5">
      <div style={{maxWidth:"195px"}}>
        <img
          src={poweredByImg}
          alt="Powered by iPlayMe2"
          className="img-fluid"
        />
      </div>
    </div>
  );
}
