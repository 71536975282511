import React from 'react';
import facebookImg from "../images/icon-facebook.png";
import twitterImg from "../images/icon-x.png";
import linkedInImg from "../images/icon-linkedin.png";
import youtubeImg from "../images/icon-youtube.png";
import webImg from "../images/icon-web.png";
import { IntlProvider, FormattedMessage } from "react-intl";

function Footer({ changeLanguage }) {
    return (
        <>
            <div id="al-top">
                <a href="#"></a>
            </div>

            <footer className="p-5">
                <div className="container text-center">
                    <nav className="social-menu ">
                        <ul className="d-flex justify-content-center p-2">
                            <li><a href="https://www.facebook.com/iPlayMe2/" target="_blank"><img src={facebookImg} alt="Facebook" /></a></li>
                            <li><a href="https://twitter.com/iPlayMe2" target="_blank"><img src={twitterImg} alt="Twitter" /></a></li>
                            <li><a href="https://www.linkedin.com/company/iplayme2/" target="_blank"><img src={linkedInImg} alt="Linkedin" /></a></li>
                            <li><a href="https://www.youtube.com/channel/UCc4jCx9VC4_HqJUHjqhhgAQ" target="_blank"><img src={youtubeImg} alt="YouTube" /></a></li>
                            <li><a href="http://www.iplayme2.com/" target="_blank"><img src={webImg} alt="Web" /></a></li>
                        </ul>
                    </nav>
                </div>
                <p className="text-center mt-2"><FormattedMessage id="SC.FooterRightReserved" values={{date:new Date().getUTCFullYear()}}/><br /><FormattedMessage id="SC.PersonalHub"/></p>

            </footer>

            <div className="languages p-2">
                <ul className="d-flex justify-content-center flex-wrap">
                <li><a href="#/" onClick={(event) => { event.preventDefault(); changeLanguage('en') }}>English</a></li>
                <li><a href="#/" onClick={(event) => { event.preventDefault(); changeLanguage('es') }}>Español</a></li>
                <li><a href="#/" onClick={(event) => { event.preventDefault(); changeLanguage('fr') }}>Français</a></li>
                <li><a href="#/" onClick={(event) => { event.preventDefault(); changeLanguage('it') }}>Italian</a></li>
                <li><a href="#/" onClick={(event) => { event.preventDefault(); changeLanguage('de') }}>Deutsch</a></li>
                <li><a href="#/" onClick={(event) => { event.preventDefault(); changeLanguage('nl') }}>Nederlands</a></li>
                <li><a href="#/" onClick={(event) => { event.preventDefault(); changeLanguage('pt') }}>Portuguese</a></li>
                <li><a href="#/" onClick={(event) => { event.preventDefault(); changeLanguage('hu') }}>Hungarian</a></li>
                </ul>
            </div>
        </>
    );
}

export default Footer;


