import React from 'react';
import logoIpm2 from "../images/logo-ipm2.svg";
import arrowDown from "../images/arrow-down.png";
import CarouselHeaderTop from './CarouselHeaderTop';


function Header({ changeLanguage }) {
    return (
        <>
        <header>
            <div className="headerTop">
                <CarouselHeaderTop />
            </div>
            <div className="container-fluid">
                <div className="d-flex p-3 justify-content-center">
                    <img className="logo " src={logoIpm2} alt="Logo iPM2" />
                </div>
            </div>

            <div className="lang">
                <div className="dropdown">
                    <button className="dropbtn">English <img src={arrowDown} alt="" /></button>
                    <div className="dropdown-content">
                        <a href="#/" onClick={(event) => { event.preventDefault(); changeLanguage('en') }}>English</a>
                        <a href="#/" onClick={(event) => { event.preventDefault(); changeLanguage('es') }}>Español</a>
                        <a href="#/" onClick={(event) => { event.preventDefault(); changeLanguage('fr') }}>Français</a>
                        <a href="#/" onClick={(event) => { event.preventDefault(); changeLanguage('it') }}>Italian</a>
                        <a href="#/" onClick={(event) => { event.preventDefault(); changeLanguage('de') }}>Deutsch</a>
                        <a href="#/" onClick={(event) => { event.preventDefault(); changeLanguage('nl') }}>Nederlands</a>
                        <a href="#/" onClick={(event) => { event.preventDefault(); changeLanguage('pt') }}>Portuguese</a>
                        <a href="#/" onClick={(event) => { event.preventDefault(); changeLanguage('hu') }}>Hungarian</a>
                    </div>
                </div>
            </div>
        </header>
        </>
    );
}

export default Header;
