import React from 'react';
import "../styles/main.scss";
import imgTennis from "../images/banner-download/slides/tennis.jpg";
import imgPickleball from "../images/banner-download/slides/pickleball.jpg";
import imgPadel from "../images/banner-download/slides/padel.jpg";
import imgSquash from "../images/banner-download/slides/squash.jpg";
import imgBadminton from "../images/banner-download/slides/badminton.jpg";
import imgRacquetball from "../images/banner-download/slides/racquetball.jpg";
import imgPaddlelball from "../images/banner-download/slides/paddleball.jpg";
import imgCourtTennis from "../images/banner-download/slides/court-tennis.jpg";
import imgTableTennis from "../images/banner-download/slides/table-tennis.jpg";
import imgPlatformTennis from "../images/banner-download/slides/platform-tennis.jpg";
import imgPaddleTennis from "../images/banner-download/slides/paddle-tennis.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
import BannerSlide from './BannerSlide';

export default function DownloadMaximize() {
    return (
      <div className="wrapper-banner-footer">
        <Carousel 
            autoPlay
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            useKeyboardArrows={false}
            stopOnHover={false}
            infiniteLoop={true}
            transitionTime={0}
            interval={1200}
        >
            <BannerSlide imgSlide={imgTennis} sport="Tennis" sportColor="#F7BCFF" />
            <BannerSlide imgSlide={imgPickleball} sport="Pickleball" sportColor="#FCA6A6" />
            <BannerSlide imgSlide={imgPadel} sport="Padel" sportColor="#FCFDBA" />
            <BannerSlide imgSlide={imgSquash} sport="Squash" sportColor="#BBFFC6" />
            <BannerSlide imgSlide={imgBadminton} sport="Badminton" sportColor="#6FEEFF" />
            <BannerSlide imgSlide={imgRacquetball} sport="Racquetball" sportColor="#F7BCFF" />
            <BannerSlide imgSlide={imgPaddlelball} sport="Paddle Ball" sportColor="#FCA6A6" />
            <BannerSlide imgSlide={imgCourtTennis} sport="Court Tennis" sportColor="#FCFDBA" />
            <BannerSlide imgSlide={imgTableTennis} sport="Table Tennis" sportColor="#BBFFC6" />
            <BannerSlide imgSlide={imgPlatformTennis} sport="Platform tennis" sportColor="#6FEEFF" />
            <BannerSlide imgSlide={imgPaddleTennis} sport="Paddle Tennis" sportColor="#FFDC97" />
        </Carousel>
      </div>
    );
  }